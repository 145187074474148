<template>
    <ConsignmentNoteCreateForm />
</template>

<script>
import ConsignmentNoteCreateForm from '@/components/ConsignmentNote/ConsignmentNoteCreateForm'

export default {
    name: "CreateOrder",
    components: {
        ConsignmentNoteCreateForm
    },
    data: () => ({
        breadcrumbs: [
            { title: 'Главная', link: '/' },
            { title: 'Заказ ПО', link: '/' },
            { title: 'Перечень товарных накладных по подряду', link: '/consignment-notes' },
            { title: 'Создание товарной накладной' }
        ]
    })
}
</script>

<style scoped>

</style>
